<template>
  <!-- 财务报表 —— 储值卡缴费记录 -->
  <div class="box-card" style="text-align:left;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车牌号：">
        <el-input v-model="queryForm.plate_number" placeholder="请输入车牌号" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))"></el-input>
      </el-form-item>
      <el-form-item label="类型：">
        <el-select popper-class="my-select" v-model="queryForm.card_status" placeholder="请选择类型" clearable
          @clear="queryForm.card_status = null" @change="onSubmit">
          <!-- <el-option label="全部" :value="0"></el-option> -->
          <el-option label="新增储值卡" :value="1"></el-option>
          <el-option label="储值卡充值" :value="2"></el-option>
          <el-option label="导入储值卡" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="缴费时间：">
              <el-date-picker v-model="queryForm.start_time" type="datetime" @change="onSubmit" :editable="false" placeholder="请选择开始时间" default-time="00:00:00"></el-date-picker>
              <span style="margin: 0 10px;">~</span>
              <el-date-picker v-model="queryForm.end_time" type="datetime" @change="onSubmit" :editable="false" placeholder="请选择结束时间" default-time="23:59:59"></el-date-picker>
            </el-form-item> -->
      <el-form-item label="缴费时间：">
        <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
          @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
        </el-date-picker>
        <!-- <el-date-picker v-model="queryForm.start_time" type="datetime" @change="onSubmit" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptionsStart" :editable="false" placeholder="请选择开始时间" default-time="00:00:00"></el-date-picker>
              <span style="margin: 0 10px;">~</span>
              <el-date-picker v-model="queryForm.end_time" type="datetime" @change="onSubmit" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptionsEnd" :editable="false" placeholder="请选择结束时间" default-time="23:59:59"></el-date-picker> -->
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card>
      <template slot="heard-left">
        <leCount :count="total_amount"></leCount>
      </template>
      <template slot="heard-right">
        <div class="right">
          <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
          <lbButton type="warning" icon="daochu" @click="exportFile" v-preventReClick
            v-if="isShowBtn('f56302f219a946e582a90ca3') || isShowBtn('07dc1f007d884a6b9fc8c6d5')">导出</lbButton>
        </div>
      </template>
      <div>
        <!-- 主要内容 -->
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="car_owner_card_type">
              {{ getWayName('CarOwnerCardType', row.car_owner_card_type) }}
            </template>
            <template slot-scope="{row}" slot="card_type">
              {{ row.card_status === 2 ? (getWayName('CarOwnerCardType', row.card_type) +
                getWayName('CardStatus', row.card_status)) : (getWayName('CardStatus', row.card_status) +
                  getWayName('CarOwnerCardType', row.card_type)) }}
            </template>
            <template slot-scope="{row}" slot="renew_amount">
              ￥{{ row.renew_amount.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="update_time">
              {{ $moment(row.update_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="plate_number" label="车牌号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column prop="card_type" label="类型">
            <template slot-scope="scope">
              {{ scope.row.card_status === 2 ? (getWayName('CarOwnerCardType', scope.row.card_type) +
                getWayName('CardStatus', scope.row.card_status)) : (getWayName('CardStatus', scope.row.card_status) +
                  getWayName('CarOwnerCardType', scope.row.card_type)) }}
            </template>
          </el-table-column>
          <el-table-column prop="renew_amount" label="金额">
            <template slot-scope="scope">
              ￥{{ scope.row.renew_amount.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="操作时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="operator_name" label="操作人"></el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>
  </div>
</template>

<script>
import { getCardPaymentList, getExportCardPaymenteList } from '@/api/financialStatement'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        card_type: 3,
        card_status: null,
        parking_id: '',
        plate_number: '',
        start_time: '',
        end_time: '',
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      total: 0,
      // 表格数据
      tableData: [],
      total_amount: 0,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '类型',
            prop: 'card_type',
            slot: true
          }, {
            label: '金额',
            prop: 'renew_amount',
            slot: true
          }, {
            label: '操作时间',
            prop: 'update_time',
            slot: true
          }, {
            label: '操作人',
            prop: 'operator_name'
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      pickerOptionsStart: {
        disabledDate: time => {
          const endDateVal = this.queryForm.end_time
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          const beginDateVal = this.queryForm.start_time
          if (beginDateVal) {
            return (time.getTime() < new Date(beginDateVal).getTime())
          }
        }
      }
    }
  },
  created () {
    this.fnGetCardPaymentList()
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    // // 时间开始选择器
    // startTimeStatus: function (value) {
    //   this.queryForm.start_time = value
    //   this.onSubmit()
    // },
    // // 时间结束选择器
    // endTimeStatus: function (value) {
    //   this.queryForm.end_time = value
    //   this.onSubmit()
    // },
    // 获取月租卡、储值卡缴费记录
    async fnGetCardPaymentList () {
      const res = await getCardPaymentList({
        card_type: this.queryForm.card_type,
        card_status: this.queryForm.card_status ? this.queryForm.card_status : null,
        parking_id: this.queryForm.parking_id,
        plate_number: this.queryForm.plate_number,
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log(res);
      this.total = res.Data.TotalCount
      this.tableData = res.Data.DataList
      this.total_amount = res.Data.totalCharge
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetCardPaymentList()
      // if (this.queryForm.end_time && this.queryForm.start_time) {
      //   if (this.queryForm.end_time) {
      //     this.queryForm.end_time = this.$moment(this.queryForm.end_time).format("YYYY-MM-DD") + ' 23:59:59'
      //   } else {
      //     this.queryForm.start_time = this.$moment(this.queryForm.start_time).format("YYYY-MM-DD") + ' 00:00:00'
      //   }
      //   this.fnGetCardPaymentList()
      // } else {
      //   this.fnGetCardPaymentList()
      // }
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetCardPaymentList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetCardPaymentList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetCardPaymentList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetCardPaymentList()
    // },
    // 导出
    async exportFile () {
      const res = await getCardPaymentList({
        card_type: this.queryForm.card_type,
        card_status: this.queryForm.card_status,
        parking_id: this.queryForm.parking_id,
        plate_number: this.queryForm.plate_number,
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        PageSize: 0,
        PageIndex: 0
      })
      // console.log(res);
      var DataList = res.Data.DataList
      if (DataList.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '车牌号',
            '停车场',
            '卡片类型',
            '卡片状态',
            '金额',
            '操作时间',
            '操作人'
          ]
          const filterVal = [
            'plate_number',
            'parking_name',
            'card_type',
            'card_status',
            'renew_amount',
            'update_time',
            'operator_name'
          ]
          const list = DataList
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '储值卡缴费记录' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row);
      // console.log(column);
      switch (column.property) {
        case 'update_time':
          return this.$moment(row.update_time).format('YYYY-MM-DD HH:mm:ss')
        case 'renew_amount':
          return row.renew_amount = '￥' + row.renew_amount.toFixed(2)
        case 'card_type':
          return this.getWayName('CarOwnerCardType', row[column.property])
        case 'card_status':
          return this.getWayName('CardStatus', row[column.property])
        default:
          return row[column.property]
      }
    }
  }
}
</script>

<style scoped lang="less">
.box-card{
  margin-top: 20px;
}
.top_content {
  display: flex;
  justify-content: space-between;

  .right_box {
    width: 34%;
    text-align: right;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 510px;
  }
}</style>
